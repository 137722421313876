class AuthService {
    static login(token, uid) {
        const currentTime = new Date().getTime();
        localStorage.setItem('sessionToken', token);
        localStorage.setItem('sessionUID', uid);
        localStorage.setItem('sessionTokenTimestamp', currentTime);
    }

    static logout() {
        localStorage.removeItem('sessionToken');
        localStorage.removeItem('sessionUID');
        localStorage.removeItem('sessionTokenTimestamp');
    }

    static getSessionToken() {
        return localStorage.getItem('sessionToken');
    }

    static getSessionUID() {
        return localStorage.getItem('sessionUID');
    }

    static isAuthenticated() {
        const token = localStorage.getItem('sessionToken');
        const tokenTimestamp = localStorage.getItem('sessionTokenTimestamp');

        if (!token || !tokenTimestamp) {
            return false;
        }

        const currentTime = new Date().getTime();
        const tokenAge = currentTime - parseInt(tokenTimestamp, 10);
        const maxTokenAge = 5 * 60 * 60 * 1000; // 5 hours in milliseconds

        return tokenAge < maxTokenAge;
    }

}

export default AuthService;