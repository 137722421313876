import React, {useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import {
    Box,
    Button,
    Grid,
    Stack,
    Typography,
} from "@mui/material";
import PageContainer from "src/components/container/PageContainer";
import DashboardCard from "../../../components/shared/DashboardCard";
import CustomTextField from "../../../components/forms/theme-elements/CustomTextField";
import axiosInstance from "../../../components/axiosConfig";
import Swal from "sweetalert2";

const Beneficiary = () => {
    const navigate = useNavigate();
    const [remitterMobile, setRemitterMobile] = React.useState("");
    const [firstName, setFirstName] = React.useState("");
    const [ifscCode, setIfscCode] = React.useState("");
    const [accountNumber, setAccountNumber] = React.useState("");
    const [otp, setOtp] = React.useState("");
    const [isOtp, setIsOtp] = React.useState(false);
    const [otpReference, setOtpReference] = React.useState(false);
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (remitterMobile === "" || ifscCode === "" || firstName === "" || accountNumber === "") {
            return Swal.fire("Error!", "Please fill all the fields", "error");
        }
        try {
            const response = await axiosInstance.post("dmt/beneficiaryRegistration", {
                remitterMobile: remitterMobile,
                ifscCode: ifscCode,
                firstName: firstName,
                accountNumber: accountNumber,
            });
            const resp = response.data;
            console.log(resp);
            if (resp.statusCode === "TXN") {
                return Swal.fire("Success!", resp.message, "success").then(() => {
                    navigate("/dashboard/dmt/fundTransfer");
                })
            } else if (resp.statusCode === "OTP") {
                setIsOtp(true);
                setOtpReference(resp.data.otpReference);
                return Swal.fire("Success!", resp.message, "success");
            } else {
                return Swal.fire("Error!", resp.message, "error");
            }
        } catch (error) {
            return Swal.fire("Error!", "Something went wrong", "error");
        }
    };
    const handleOtpSubmit = async (event) => {
        event.preventDefault();
        if (remitterMobile === "" || ifscCode === "" || firstName === "" || accountNumber === "") {
            return Swal.fire("Error!", "Please fill all the fields", "error");
        }
        try {
            const response = await axiosInstance.post("dmt/otpVerification", {
                otpReference: otpReference,
                mobile: remitterMobile,
                type: "Beni",
                otp: otp,
            });
            const resp = response.data;
            console.log(resp);
            if (resp.statusCode === "TXN") {
                Swal.fire("Success!", resp.message, "success");
                window.location.href = "/dashboard/";
            } else {
                return Swal.fire("Error!", resp.message, "error");
            }
        } catch (error) {
            return Swal.fire("Error!", "Something went wrong", "error");
        }
    };

    return (
        <PageContainer title="WizPays - Bneficiary Page" description="WizPays - Bneficiary Page ">
            <Grid container spacing={2} xs={12} justifyContent="center">
                <Grid item display={isOtp ? "none" : ""} xs={12} md={6} lg={6} sx={{mt: {xs: 2, md: 0}}}>
                    <DashboardCard title="Add New Bneficiary">
                        <Stack>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Box mt="25px">
                                        <Typography
                                            variant="subtitle1"
                                            fontWeight={600}
                                            component="label"
                                            htmlFor="remitterMobile"
                                            mb="5px"
                                        >
                                            Remitter Number
                                        </Typography>
                                        <CustomTextField
                                            id="remitterMobile"
                                            type="text"
                                            variant="outlined"
                                            value={remitterMobile}
                                            onChange={(e) => setRemitterMobile(e.target.value)}

                                            fullWidth
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box mt="25px">
                                        <Typography
                                            variant="subtitle1"
                                            fontWeight={600}
                                            component="label"
                                            htmlFor="firstName"
                                            mb="5px"
                                        >
                                            First Name
                                        </Typography>
                                        <CustomTextField
                                            id="firstName"
                                            type="text"
                                            variant="outlined"
                                            value={firstName}
                                            onChange={(e) => setFirstName(e.target.value)}

                                            fullWidth
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Box mt="25px">
                                        <Typography
                                            variant="subtitle1"
                                            fontWeight={600}
                                            component="label"
                                            htmlFor="ifscCode"
                                            mb="5px"
                                        >
                                            IFSC Code
                                        </Typography>
                                        <CustomTextField
                                            id="ifscCode"
                                            type="text"
                                            variant="outlined"
                                            value={ifscCode}
                                            onChange={(e) => setIfscCode(e.target.value)}

                                            fullWidth
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box mt="25px">
                                        <Typography
                                            variant="subtitle1"
                                            fontWeight={600}
                                            component="label"
                                            htmlFor="accountNumber"
                                            mb="5px"
                                        >
                                            Account Number
                                        </Typography>
                                        <CustomTextField
                                            id="accountNumber"
                                            type="text"
                                            variant="outlined"
                                            value={accountNumber}
                                            onChange={(e) => setAccountNumber(e.target.value)}

                                            fullWidth
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            <Box mt="25px">
                                <Button
                                    color="primary"
                                    variant="contained"
                                    size="large"
                                    fullWidth
                                    component={Link}
                                    type="submit"
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </Button>
                            </Box>
                        </Stack>
                    </DashboardCard>
                </Grid>
                <Grid item display={isOtp ? "" : "none"} xs={12} md={6} lg={6} sx={{mt: {xs: 2, md: 0}}}>
                    <DashboardCard title="Add New Bneficiary">
                        <Stack>
                            <Box mt="25px">
                                <Typography
                                    variant="subtitle1"
                                    fontWeight={600}
                                    component="label"
                                    htmlFor="otp"
                                    mb="5px"
                                >
                                    Enter OTP
                                </Typography>
                                <CustomTextField
                                    id="otp"
                                    type="text"
                                    variant="outlined"
                                    value={otp}
                                    onChange={(e) => setOtp(e.target.value)}
                                    fullWidth
                                />
                            </Box>
                            <Box mt="35px">
                                <Button
                                    color="primary"
                                    variant="contained"
                                    size="large"
                                    fullWidth
                                    component={Link}
                                    type="submit"
                                    onClick={handleOtpSubmit}
                                >
                                    Submit
                                </Button>
                            </Box>
                        </Stack>
                    </DashboardCard>
                </Grid>
            </Grid>
        </PageContainer>
    );
};

export default Beneficiary;
