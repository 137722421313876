import React, {useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import {
    Box,
    Button, FormControl,
    Grid, MenuItem, Select,
    Stack,
    Typography,
} from "@mui/material";
import PageContainer from "src/components/container/PageContainer";
import DashboardCard from "../../../components/shared/DashboardCard";
import CustomTextField from "../../../components/forms/theme-elements/CustomTextField";
import axiosInstance from "../../../components/axiosConfig";
import Swal from "sweetalert2";
import {fetchUserData} from "../../../components/api/UserData";
import {useUser} from "../../../components/UserContext";
import useGeoLocation from "../getLocation";

const Transfer = () => {
    const {user, setUser} = useUser();
    const navigate = useNavigate();
    const [dmtBeneficiaries, setDmtBeneficiaries] = React.useState([]);
    const [selectedBeneficiary, setSelectedBeneficiary] = React.useState({});
    const [beneficiaryId, setBeneficiaryId] = React.useState("");
    const [transferAmount, setTransferAmount] = React.useState("");
    const [remarks, setRemarks] = React.useState("Dmt Fund Transfer");
    const location = useGeoLocation();

    useEffect(() => {
        const response = axiosInstance.get("dmt/getDmtBeneficiaries");
        response.then((response) => {
            if (response.data.status) {
                setDmtBeneficiaries(response.data.data);
            }
        });
    }, []);
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (selectedBeneficiary === {} || transferAmount === "" || remarks === "") {
            return Swal.fire("Error!", "Please fill all the fields", "error");
        }
        try {
            if (location.loaded) {
                if (location.coordinates) {
                    const response = await axiosInstance.post("dmt/createFundTransfer", {
                        beneficiaryId: selectedBeneficiary.beneficiaryId,
                        transferAmount: transferAmount,
                        remarks: remarks,
                        latitude: location.coordinates.lat,
                        longitude: location.coordinates.lng,
                    });
                    const resp = response.data;
                    console.log(resp);
                    if (resp.statusCode === "TXN") {
                        const updatedUserData = await fetchUserData();
                        setUser(updatedUserData);
                        return Swal.fire("Success!", resp.message, "success").then(() => {
                            navigate("/dashboard");
                        })
                    } else {
                        return Swal.fire("Error!", resp.message, "error");
                    }
                } else if (
                    location.error &&
                    location.error.message === "User denied Geolocation"
                ) {
                    return Swal.fire("Error!", "Location not found", "error");
                }
            } else {
                Swal.fire("Error!", "Location not found", "error");
            }
        } catch (error) {
            return Swal.fire("Error!", "Something went wrong", "error");
        }
    };

    return (
        <PageContainer title="WizPays - Fund Transfer" description="WizPays - Fund Transfer">
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} md={6} lg={6} sx={{mt: {xs: 2, md: 0}}}>
                    <DashboardCard title="Fund Transfer">
                        <Stack>
                            <Box mt="10px">
                                <FormControl fullWidth>
                                    <Typography
                                        variant="subtitle1"
                                        fontWeight={600}
                                        component="label"
                                        htmlFor="dmtBenificiaries"
                                        mb="5px"
                                    >
                                        Select Beneficiary
                                    </Typography>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="dmtBenificiaries"
                                        value={selectedBeneficiary}
                                        label="Select Beneficiary"
                                        onChange={(e) => setSelectedBeneficiary(e.target.value)}
                                    >
                                        <MenuItem value={0} selected={true}>
                                            Select Bank Beneficiary
                                        </MenuItem>
                                        {dmtBeneficiaries.map((item, index) => (
                                            <MenuItem key={index} value={item}>
                                                {item.bankName + " - " + item.accountNumber}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box mt="25px">
                                <Typography
                                    variant="subtitle1"
                                    fontWeight={600}
                                    component="label"
                                    htmlFor="mobileNumber"
                                    mb="5px"
                                >
                                    Mobile Number
                                </Typography>
                                <CustomTextField
                                    id="mobileNumber"
                                    type="number"
                                    variant="outlined"
                                    value={selectedBeneficiary.mobile}
                                    disabled
                                    fullWidth
                                />
                            </Box>
                            <Box mt="25px">
                                <Typography
                                    variant="subtitle1"
                                    fontWeight={600}
                                    component="label"
                                    htmlFor="accountNumber"
                                    mb="5px"
                                >
                                    Account Number
                                </Typography>
                                <CustomTextField
                                    id="accountNumber"
                                    type="number"
                                    variant="outlined"
                                    value={selectedBeneficiary.accountNumber}
                                    disabled
                                    fullWidth
                                />
                            </Box>
                            <Box mt="25px">
                                <Typography
                                    variant="subtitle1"
                                    fontWeight={600}
                                    component="label"
                                    htmlFor="bankName"
                                    mb="5px"
                                >
                                    Bank Name
                                </Typography>
                                <CustomTextField
                                    id="bankName"
                                    type="text"
                                    variant="outlined"
                                    value={selectedBeneficiary.bankName ?? "N/A"}
                                    disabled
                                    fullWidth
                                />
                            </Box>
                            <Box mt="25px">
                                <Typography
                                    variant="subtitle1"
                                    fontWeight={600}
                                    component="label"
                                    htmlFor="ifscCode"
                                    mb="5px"
                                >
                                    IFSC Code
                                </Typography>
                                <CustomTextField
                                    id="ifscCode"
                                    type="text"
                                    variant="outlined"
                                    value={selectedBeneficiary.ifscCode}
                                    disabled
                                    fullWidth
                                />
                            </Box>
                            <Box mt="25px">
                                <Typography
                                    variant="subtitle1"
                                    fontWeight={600}
                                    component="label"
                                    htmlFor="transferAmount"
                                    mb="5px"
                                >
                                    Transfer Amount
                                </Typography>
                                <CustomTextField
                                    id="transferAmount"
                                    type="text"
                                    variant="outlined"
                                    value={transferAmount}
                                    onChange={(e) => setTransferAmount(e.target.value)}
                                    fullWidth
                                />
                            </Box>
                            <Box mt="25px">
                                <Typography
                                    variant="subtitle1"
                                    fontWeight={600}
                                    component="label"
                                    htmlFor="remarks"
                                    mb="5px"
                                >
                                    Remarks
                                </Typography>
                                <CustomTextField
                                    id="remarks"
                                    type="text"
                                    variant="outlined"
                                    value={remarks}
                                    onChange={(e) => setRemarks(e.target.value)}
                                    multiline
                                    rows={4}
                                    fullWidth
                                />
                            </Box>
                            <Box mt="25px">
                                <Button
                                    color="primary"
                                    variant="contained"
                                    size="large"
                                    fullWidth
                                    component={Link}
                                    type="submit"
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </Button>
                            </Box>
                        </Stack>
                    </DashboardCard>
                </Grid>
            </Grid>
        </PageContainer>
    );
};

export default Transfer;
