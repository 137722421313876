import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client'; // Import ReactDOM from 'react-dom'
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import {UserProvider} from "./components/UserContext";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Suspense fallback={<div>Loading...</div>}>
    <BrowserRouter basename="/">
        <UserProvider>
      <App />
        </UserProvider>
    </BrowserRouter>
  </Suspense>
);
