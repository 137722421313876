import React, {useEffect, useState} from "react";
import axiosInstance from "../../../components/axiosConfig";
import {Box, CircularProgress, Grid, Typography} from "@mui/material";
import YearlyBreakup from "../components/YearlyBreakup";
import PageContainer from "../../../components/container/PageContainer";
import DashboardCard from "../../../components/shared/DashboardCard";
import CustomTextField from "../../../components/forms/theme-elements/CustomTextField";

const AllVouchersList = () => {
    const [loading, setLoading] = useState(true);
    const [giftData, setGiftData] = useState([]);
    const [filteredGiftData, setFilteredGiftData] = useState([]);

    useEffect(() => {
        fetchGiftVoucherData();
    }, []);

    const fetchGiftVoucherData = async () => {
        try {
            const response = await axiosInstance.get("voucher/getAllBrandVoucher?limit=All");
            const {data} = response.data;
            setGiftData(data);
            setFilteredGiftData(data);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };
    return (
        <PageContainer title="WizPays - All Vouchers Page" description="WizPays - All Vouchers Page ">
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} md={12} lg={12}>
                    <DashboardCard title="Gift Card & Brand Vouchers">
                        <Grid item xs={12} marginBottom="20px">
                            {/*  Search Field  */}
                            <Box mt="25px">
                                <CustomTextField
                                    id="search"
                                    type="text"
                                    variant="outlined"
                                    label="Search Vouchers"
                                    onChange={(e) => {
                                        const filteredList = giftData.filter((item) => {
                                            return item.productName.toLowerCase().includes(e.target.value.toLowerCase());
                                        });
                                        setFilteredGiftData(filteredList);
                                    }}
                                    fullWidth
                                />
                            </Box>
                        </Grid>
                        <Box>
                            {loading ? (
                                <Grid item xs={12} style={{textAlign: "center", marginTop: "20px"}}>
                                    <CircularProgress/>
                                </Grid>
                            ) : (
                                <Grid container spacing={3} sx={{marginBottom: 3}}>
                                    {filteredGiftData.length === 0 && (
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="subtitle2"
                                                style={{
                                                    fontWeight: 1000,
                                                    fontSize: "18px",
                                                    marginBottom: "10px",
                                                    marginTop: "20px",
                                                    textAlign: "center",
                                                    color: "red",
                                                }}
                                            >
                                                No vouchers found
                                            </Typography>
                                        </Grid>
                                    )}
                                    {filteredGiftData.map((item, index) => (
                                        <Grid item xs={6} lg={3} key={index}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <YearlyBreakup
                                                        name={item.productName + " (₹" + item.minPrice + " - ₹" + item.maxPrice + ")"}
                                                        iconSrc={item.mobile}
                                                        link={"/dashboard/vouchers/" + item.productKey + "/" + item.productName + "/" + item.minPrice + "/" + item.maxPrice + "?productImage=" + item.mobile}
                                                        color="white"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        </Box>
                    </DashboardCard>
                </Grid>
            </Grid>
        </PageContainer>
    )
}

export default AllVouchersList