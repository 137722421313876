import axiosInstance from "../axiosConfig";
import AuthService from "src/views/authentication/AuthService";

export const fetchUserData = async () => {
  try {
    const body = {
      uid: AuthService.getSessionToken(),
    };
    const response = await axiosInstance.get("user/getUserData", body);
    const { data } = response.data;
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
