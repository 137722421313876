import React from 'react';
import {Card, Typography} from '@mui/material';
import {Link} from 'react-router-dom';

const YearlyBreakup = ({name, iconSrc, link, color}) => {
    return (
        <Link to={link} style={{textDecoration: 'none', color: 'inherit'}}>
            <Card
                sx={{
                    cursor: 'pointer',
                    padding: '18px',
                    backgroundColor: color,
                    borderRadius: '10px',
                    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
                    color: color === 'white' ? 'black' : 'white',
                }}
            >
                <div style={{display: 'flex', alignItems: 'center', textAlign: 'center'}}>
                    <img
                        src={iconSrc}
                        style={{width: '40px', marginRight: '8px'}}
                        alt={name}
                    />
                    <Typography variant="subtitle2" style={{
                        fontWeight: 600,
                        fontSize: '12px',
                        lineBreak: 'strict',
                        maxWidth: '150px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                    }}>
                        {name}
                    </Typography>
                </div>
            </Card>
        </Link>
    );
};

export default YearlyBreakup;
