import {Backdrop, Box, CircularProgress, CssBaseline, ThemeProvider, Typography,} from "@mui/material";
import {useRoutes} from "react-router-dom";
import Router from "./routes/Router";

import {baselightTheme} from "./theme/DefaultColors";
import React, {useEffect, useState} from "react";
import axiosInstance from "./components/axiosConfig";
import useGeoLocation from "./views/dashboard/getLocation";
import {useLocation} from "react-router";

function App() {
    const [loading, setLoading] = useState(false);
    const location = useGeoLocation();

    const {pathname} = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        axiosInstance.interceptors.request.use((config) => {
            setLoading(true);
            return config;
        });
        const responseInterceptor = axiosInstance.interceptors.response.use(
            (response) => {
                setLoading(false);
                return response;
            },
            (error) => {
                setLoading(false);
                return Promise.reject(error);
            }
        );
        return () => {
            axiosInstance.interceptors.response.eject(responseInterceptor);
        };
    }, []);

    const routing = useRoutes(Router);
    const theme = baselightTheme;

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            {routing}
            <Backdrop
                sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={loading}
            >
                <Box
                    sx={{
                        alignContent: "center",
                        textAlign: "center",
                    }}
                    p={2}
                >
                    <CircularProgress color="inherit" style={{marginBottom: "10px"}}/>
                    <Typography
                        sx={{
                            fontSize: "1.5rem",
                            fontWeight: "bold",
                            color: "#fff",
                            textAlign: "center",
                        }}
                    >
                        Please Wait...
                    </Typography>
                </Box>
            </Backdrop>
        </ThemeProvider>
    );
}

export default App;
