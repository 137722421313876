import React, {useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import {
    Box,
    Button,
    Grid,
    Stack,
    Typography,
} from "@mui/material";
import PageContainer from "src/components/container/PageContainer";
import DashboardCard from "../../../components/shared/DashboardCard";
import CustomTextField from "../../../components/forms/theme-elements/CustomTextField";
import axiosInstance from "../../../components/axiosConfig";
import Swal from "sweetalert2";

const Remitter = () => {
    const navigate = useNavigate();
    const [mobileNumber, setMobileNumber] = React.useState("");
    const [pinCode, setPinCode] = React.useState("");
    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [otp, setOtp] = React.useState("");
    const [isOtp, setIsOtp] = React.useState(false);
    const [otpReference, setOtpReference] = React.useState(false);
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (mobileNumber === "" || pinCode === "" || firstName === "" || lastName === "") {
            return Swal.fire("Error!", "Please fill all the fields", "error");
        }
        try {
            const response = await axiosInstance.post("dmt/remitterRegistration", {
                mobileNumber: mobileNumber,
                pinCode: pinCode,
                firstName: firstName,
                lastName: lastName,
            });
            const resp = response.data;
            console.log(resp);
            if (resp.statusCode === "TXN") {
                Swal.fire("Success!", resp.message, "success").then(() => {
                    navigate("/dashboard/dmt/beneficiary");
                });
            } else if (resp.statusCode === "OTP") {
                setIsOtp(true);
                setOtpReference(resp.data.otpReference);
                return Swal.fire("Success!", resp.message, "success");
            } else {
                return Swal.fire("Error!", resp.message, "error");
            }
        } catch (error) {
            return Swal.fire("Error!", "Something went wrong", "error");
        }
    };
    const handleOtpSubmit = async (event) => {
        event.preventDefault();
        if (mobileNumber === "" || pinCode === "" || firstName === "" || lastName === "") {
            return Swal.fire("Error!", "Please fill all the fields", "error");
        }
        try {
            const response = await axiosInstance.post("dmt/otpVerification", {
                otpReference: otpReference,
                mobile: mobileNumber,
                type: "Remi",
                otp: otp,
            });
            const resp = response.data;
            console.log(resp);
            if (resp.statusCode === "TXN") {
                Swal.fire("Success!", resp.message, "success").then(() => {
                    navigate("/dashboard/dmt/beneficiary");
                });
            } else {
                return Swal.fire("Error!", resp.message, "error");
            }
        } catch (error) {
            return Swal.fire("Error!", "Something went wrong", "error");
        }
    };

    return (
        <PageContainer title="WizPays - Remitter Page" description="WizPays - Remitter Page ">
            <Grid container spacing={2} xs={12} justifyContent="center">
                <Grid item display={isOtp ? "none" : ""} xs={12} md={6} lg={6} sx={{mt: {xs: 2, md: 0}}}>
                    <DashboardCard title="Add New Remitter">
                        <Stack>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Box mt="25px">
                                        <Typography
                                            variant="subtitle1"
                                            fontWeight={600}
                                            component="label"
                                            htmlFor="mobileNumber"
                                            mb="5px"
                                        >
                                            Mobile Number
                                        </Typography>
                                        <CustomTextField
                                            id="mobileNumber"
                                            type="text"
                                            variant="outlined"
                                            value={mobileNumber}
                                            onChange={(e) => setMobileNumber(e.target.value)}

                                            fullWidth
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box mt="25px">
                                        <Typography
                                            variant="subtitle1"
                                            fontWeight={600}
                                            component="label"
                                            htmlFor="pinCode"
                                            mb="5px"
                                        >
                                            Pin Code
                                        </Typography>
                                        <CustomTextField
                                            id="pinCode"
                                            type="text"
                                            variant="outlined"
                                            value={pinCode}
                                            onChange={(e) => setPinCode(e.target.value)}

                                            fullWidth
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Box mt="25px">
                                        <Typography
                                            variant="subtitle1"
                                            fontWeight={600}
                                            component="label"
                                            htmlFor="lastName"
                                            mb="5px"
                                        >
                                            First Name
                                        </Typography>
                                        <CustomTextField
                                            id="lastName"
                                            type="text"
                                            variant="outlined"
                                            value={firstName}
                                            onChange={(e) => setFirstName(e.target.value)}

                                            fullWidth
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box mt="25px">
                                        <Typography
                                            variant="subtitle1"
                                            fontWeight={600}
                                            component="label"
                                            htmlFor="pinCode"
                                            mb="5px"
                                        >
                                            Last Name
                                        </Typography>
                                        <CustomTextField
                                            id="pinCode"
                                            type="text"
                                            variant="outlined"
                                            value={lastName}
                                            onChange={(e) => setLastName(e.target.value)}

                                            fullWidth
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            <Box mt="25px">
                                <Button
                                    color="primary"
                                    variant="contained"
                                    size="large"
                                    fullWidth
                                    component={Link}
                                    type="submit"
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </Button>
                            </Box>
                        </Stack>
                    </DashboardCard>
                </Grid>
                <Grid item display={isOtp ? "" : "none"} xs={12} md={6} lg={6} sx={{mt: {xs: 2, md: 0}}}>
                    <DashboardCard title="Add New Remitter">
                        <Stack>
                            <Box mt="25px">
                                <Typography
                                    variant="subtitle1"
                                    fontWeight={600}
                                    component="label"
                                    htmlFor="otp"
                                    mb="5px"
                                >
                                    Enter OTP
                                </Typography>
                                <CustomTextField
                                    id="otp"
                                    type="text"
                                    variant="outlined"
                                    value={otp}
                                    onChange={(e) => setOtp(e.target.value)}
                                    fullWidth
                                />
                            </Box>
                            <Box mt="35px">
                                <Button
                                    color="primary"
                                    variant="contained"
                                    size="large"
                                    fullWidth
                                    component={Link}
                                    type="submit"
                                    onClick={handleOtpSubmit}
                                >
                                    Submit
                                </Button>
                            </Box>
                        </Stack>
                    </DashboardCard>
                </Grid>
            </Grid>
        </PageContainer>
    );
};

export default Remitter;
