import React, { createContext, useContext, useState, useEffect } from "react";
import AuthService from "src/views/authentication/AuthService";
import { fetchUserData } from "src/components/api/UserData";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const userData = await fetchUserData();
            setUser(userData);
        } catch (error) {
            if (error.response.status === 401) {
                await AuthService.logout();
                navigate("auth/login", { replace: true });
                return Swal.fire('Error!', "Login Expired", 'error');
            }
            console.error("Error fetching data:", error);
        }
    };

    return (
        <UserContext.Provider value={{ user, setUser }}>
            {children}
        </UserContext.Provider>
    );
};
